<template>
    <div>
        <scholarship-page>
            <template v-slot:header>
                <div>
                    <Breadcrumbs
                        :items="items"
                    />
                    <h1>{{ $t('ostrogradskiTitle') }}</h1>
                    <h2 class="sec-title">{{ $t('ostrogradskiCalendar') }}</h2>
                    <h3 v-html="$t('howToApplyOstrogradski')"></h3>
                </div>
            </template>

            <template v-slot:content>
                <p class="content-text" v-html="$t('ostrogradskiProgramText1')"></p>
                <p class="content-text" v-html="$t('ostrogradskiProgramText2')"></p>

                <div class="table-content">
                    <div class="mobile-table_wrapper">
                        <p class="content-title table-title">II — {{ $t('forCandidats') }}</p>

                        <div class="table-wrap">
                            <div class="table-row">
                                <div class="table-row-title">{{ $t('columnTitle1') }}</div>
                                <div class="table-row-title">{{ $t('columnTitle2') }}</div>
                                <div class="table-row-title">{{ $t('columnTitle3') }}</div>
                            </div>
                            <div class="table-row">
                                <div class="table-row-item">
                                    <p>{{ $t('ostrogradskiTableItem1') }}</p>
                                    <p>{{ $t('ostrogradskiTableItem2') }}</p>
                                    <p>{{ $t('ostrogradskiTableItem3') }}</p>
                                </div>
                                <div class="table-row-item">
                                    <p v-html="$t('ostrogradskiForm')"></p>
                                    <p>{{ $t('passport') }}</p>
                                    <p>{{ $t('cv') }}</p>
                                    <p>{{ $t('ostrogradskiTableItem5') }}</p>
                                    <p>{{ $t('ostrogradskiTableItem6') }}</p>
                                    <p>{{ $t('ostrogradskiTableItem7') }}</p>
                                    <p>{{ $t('ostrogradskiTableItem8') }}</p>
                                </div>
                                <div class="table-row-item">
                                    <p>{{ $t('visa') }}</p>
                                    <p>{{ $t('ostrogradskiTableItem9') }}</p>
                                    <p>{{ $t('ostrogradskiTableItem10') }}</p>
                                    <p>{{ $t('ostrogradskiTableItem11') }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="info-wrap">
                            <div class="info-img-wrap">
                                <img src="@/assets/images/Ostrogradski.png"/>
                            </div>
                            <div>
                                <p class="name">{{ $t('ostrogradskiName') }}</p>
                                <p class="info">{{ $t('ostrogradskiDescription') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

        </scholarship-page>
    </div>
</template>

<script>
import ScholarshipPage from "@/components/ScholarshipPage";
import Breadcrumbs from '@/components/breadcrumbs';

export default {
    components: {
        ScholarshipPage,
        Breadcrumbs,
    },

    data: () => ({
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'boursesEtudes',
                href: '#',
            },
            {
                text: 'ostrogradski',
                href: '#',
            }
        ]
    }),
}
</script>

<style lang="scss">
.sec-title {
  line-height: 30px;
  padding: 10px 0px;
}
.program-text {
    margin: 10px 0;
}
</style>